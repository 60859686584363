import React from 'react'

import Link from '../link/index'

import { Scrollin, ScrollItem } from '../scrollin/index'

import styles from './index.module.scss'


const Book = (props) => (
    <div className={styles.book}>
        <Scrollin rate={0.1} className={styles.image}>
            <ScrollItem delay={0}><img src={props.image} alt="" /></ScrollItem>
        </Scrollin>
        <div className={styles.about}>
            <div className={styles.information}>
                <Scrollin>
                    <ScrollItem tagName="em">INFORMATION</ScrollItem>
                    <h2>
                        <ScrollItem tagName="strong" delay={100}>{props.title}</ScrollItem>

                        {props.subtitle && (
                            <ScrollItem tagName="span" delay={150}>{props.subtitle}</ScrollItem>
                        )}
                    </h2>
                    <div className={styles.author}>
                        <ScrollItem tagName="dl" delay={200}>
                            <dt>著者</dt>
                            <dd>{props.author}</dd>
                        </ScrollItem>
                    </div>
                    <div className={styles.description}>
                        {props.description.map((text, i) => (
                            <ScrollItem key={i} tagName="p" delay={200 + 50 * i}>{text}</ScrollItem>
                        ))}
                    </div>

                    <div className={styles.data}>
                        <table>
                            <tbody>
                                <ScrollItem tagName="tr" delay={400 + 50 * 0}>
                                    <th>ISBN</th><td>{props.isbn}</td>
                                </ScrollItem>
                                <ScrollItem tagName="tr" delay={400 + 50 * 1}>
                                    <th>出版社</th><td>{props.publisher}</td>
                                </ScrollItem>
                                <ScrollItem tagName="tr" delay={400 + 50 * 2}>
                                    <th>判型</th><td>{props.format}</td>
                                </ScrollItem>
                                <ScrollItem tagName="tr" delay={400 + 50 * 3}>
                                    <th>ページ数</th><td>{props.pages}</td>
                                </ScrollItem>
                                <ScrollItem tagName="tr" delay={400 + 50 * 4}>
                                    <th>定価</th><td>{props.price}</td>
                                </ScrollItem>
                                <ScrollItem tagName="tr" delay={400 + 50 * 5}>
                                    <th>発行年月日</th><td>{props.issued}</td>
                                </ScrollItem>
                            </tbody>
                        </table>
                    </div>
                </Scrollin>
            </div>

            <Scrollin className={styles.availables}>
                <ScrollItem tagName="em">AVAILABLE</ScrollItem>
                <ul>
                    {props.links.map(({ url, text }, i) => (
                        <ScrollItem key={i} tagName="li" delay={100 + 50 * i}>
                            <Link href={url}>{text}</Link>
                        </ScrollItem>
                    ))}
                </ul>
            </Scrollin>
        </div>
    </div>
)

export default Book