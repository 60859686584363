import React from 'react'

import Layout from 'src/components/layout/index'
import SEO from 'src/components/seo/index'
import Book from 'src/components/book/index'
import Footer from 'src/components/footer/index'

import { Scrollin, ScrollItem } from 'src/components/scrollin/index'

import styles from './index.module.scss'


const BooksPage = () => (
    <React.Fragment>
        <Layout className={styles.books}>
            <SEO lang="ja" title="著書一覧" path="/books/" description="作家・栗原明志(クリハラ アカシ)の著書とプロフィールをご紹介します。" />

            <Scrollin className={styles.title}>
                <ScrollItem tagName="h1">Books</ScrollItem>
            </Scrollin>

            <section className={styles.message}>
                <Scrollin>
                    <ScrollItem tagName="h2" delay={100}>Message</ScrollItem>
                    <div>
                        <div className={styles.text}>
                            <ScrollItem tagName="p" delay={200}>本、について考えて来た。裂け目に安住するものたちの系譜を。</ScrollItem>
                            <ScrollItem tagName="p" delay={300}>社会の表面に浮かび上がることもあるが、ほとんどの人々に知られることすらなくとも、受け継がれるべきものは受け継がれて行くだろう。日本語という九十度回転させられた言語の走査が、より複雑なものにならないと言えば嘘になるのだろうが、決して不可能ではないと示したい。</ScrollItem>
                        </div>
                    </div>
                </Scrollin>
            </section>

            <section className={styles.booklist}>
                <div>
                    <Book
                        image={`/img/book_image_1.jpg`}
                        title={`浮きよばなれ`}
                        subtitle={`島国の彼岸へと漕ぎ出す日本文学芸術論`}
                        author={`栗原 明志`}
                        description={[
                            `古事記、万葉集、源氏物語、保元物語、日蓮、徒然草、南総里見八犬伝、歌川国芳、河口慧海、幸田露伴、山田美妙、種田山頭火、小津安二郎、西馬音内盆踊り……。`,
                            `日本の歴史に名を刻んだ偉業は、同時にこの「日本」を内奥から突き破るかのごとき破格の挑戦でもあった！`,
                            `「この島国に居ながらにして平然と外部へ飛び出していった者たち」を召喚し、古代の記紀神話から21世紀のIT社会にまで連なる、文学・芸術の知られざる系譜を縦横無尽に語りつくす！`
                        ]}
                        isbn={`9784861827754`}
                        publisher={`作品社`}
                        format={`4-6`}
                        pages={`380ページ`}
                        price={`2400円（本体）`}
                        issued={`2019年09月`}
                        links={[
                            { text: `HMV & BOOKS ONLINE`, url: `https://www.books.or.jp/banner?id=9784861827754&sitename=hmv` },
                            { text: `honto`, url: `https://honto.jp/netstore/pd-book_29855549.html` },
                            { text: `紀伊國屋書店`, url: `https://www.kinokuniya.co.jp/f/dsg-01-9784861827754` },
                            { text: `Honya Club`, url: `https://www.honyaclub.com/shop/goods/goods.aspx?goods=19708074` },
                            { text: `TSUTAYA ONLINE`, url: `http://shop.tsutaya.co.jp/book/product/9784861827754/` },
                            { text: `7net shopping`, url: `https://7net.omni7.jp/detail_isbn/9784861827754` },
                            { text: `e-hon`, url: `https://www.e-hon.ne.jp/bec/SA/Detail?refISBN=9784861827754&a8=um8TvmNQPVCkrGk_lJC1knUanQB9Clb_lJCmz8UTb1E4veIvTc5lLew1id5iRdGn.7GT.HwaCcg3pHsHsDEkvY5PoDE-iDEqzDr1imRP8QBPbdN_TYGz0eElEBlny86.-nCZ.cImAcIOsnCk.1FQPV8kWnCbRV6ZRVyd6dI.z1Eni78Vzm8TMs00000017805001&=` },
                            { text: `Rakutenブックス`, url: `https://books.rakuten.co.jp/rb/16034509/?scid=af_pc_etc&sc2id=af_111_0_10000453` },
                            { text: `amazon`, url: `https://www.amazon.co.jp/s?k=9784861827754&tag=books029-22` }
                        ]}
                    />
                    <Book
                        image={`/img/book_image_2.jpg`}
                        title={`書`}
                        author={`栗原 明志`}
                        description={[
                            `芸術が芸術として存立し得なくなった現代。著名な書道家の「彼」と作家を志す「僕」との間の、微かな、距離感をあしらうかのような交流。個人というものの外殻そのものを手探りしながら、表面を差し置いて受け渡される感性の極限を求める飽くなき挑戦。翻訳文体を日本語の側から取り込みに行く主体性の冒険！`
                        ]}
                        isbn={`4329004512`}
                        publisher={`現代思潮新社`}
                        format={`4-6`}
                        pages={`284ページ`}
                        price={`2000円（本体）`}
                        issued={`2007年05月`}
                        links={[
                            { text: `HMV & BOOKS ONLINE`, url: `https://www.hmv.co.jp/artist_%E6%A0%97%E5%8E%9F%E6%98%8E%E5%BF%97_200000000277270/item_%E6%9B%B8_3425549` },
                            { text: `honto`, url: `https://honto.jp/netstore/pd-book_02790490.html` },
                            { text: `紀伊國屋書店`, url: `https://www.kinokuniya.co.jp/f/dsg-01-9784329004512` },
                            { text: `Honya Club`, url: `https://www.honyaclub.com/shop/g/g12347834/` },
                            { text: `TSUTAYA ONLINE`, url: `https://sp.shop.tsutaya.co.jp/book/product/9784329004512/` },
                            { text: `7net shopping`, url: `https://7net.omni7.jp/detail/1102429994` },
                            { text: `e-hon`, url: `https://www.e-hon.ne.jp/bec/SA/Detail?refShinCode=0100000000000031894467&Action_id=121&Sza_id=GG` },
                            { text: `Rakutenブックス`, url: `https://books.rakuten.co.jp/rb/4402180/?l-id=search-c-item-img-02` },
                            { text: `amazon`, url: `https://www.amazon.co.jp/%E6%9B%B8-%E6%A0%97%E5%8E%9F-%E6%98%8E%E5%BF%97/dp/4329004518` }
                        ]}
                    />
                </div>
            </section>

            <section className={styles.profile}>
                <Scrollin className={styles.profileContent}>
                    <div className={styles.profileText}>
                        <ScrollItem tagName="h2" delay={0}>Profile</ScrollItem>
                        <h3>
                            <ScrollItem tagName="strong" delay={100}>栗原 明志</ScrollItem>
                            <ScrollItem tagName="small" delay={100}>くりはら あかし</ScrollItem>
                        </h3>
                        <ul className={styles.profileSns}>
                            <ScrollItem tagName="li" delay={200}><a href="https://note.com/akashikurihara" target="_blank" rel="noopener noreferrer"><img src="/img/note.svg" alt="note" /></a></ScrollItem>
                            <ScrollItem tagName="li" delay={200}><a href="https://ja-jp.facebook.com/people/%E6%A0%97%E5%8E%9F%E6%98%8E%E5%BF%97/100011679516167" target="_blank" rel="noopener noreferrer"><img src="/img/facebook.svg" alt="facebook" /></a></ScrollItem>
                            {/* <li><a href="https://ja-jp.facebook.com/people/%E6%A0%97%E5%8E%9F%E6%98%8E%E5%BF%97/100011679516167" target="_blank" rel="noopener noreferrer"><img src="/img/twitter.svg" alt="twitter" /></a></li> */}
                        </ul>
                        <ScrollItem tagName="p" delay={300}>1971年東京生まれ。作家、東京大学教養学部教養学科卒。</ScrollItem>
                        <ScrollItem tagName="p" delay={400}>現在、株式会社TBRの代表取締役として、音楽レーベルや盆踊チームなどイベントのプロデュースも手掛ける。著書に、『書』(現代思潮新社、2007年)、『浮きよばなれ～島国の彼岸へと漕ぎ出す日本文学芸術論』（作品社、2019年）。</ScrollItem>
                    </div>
                    <div className={styles.profileImage}>
                        <ScrollItem delay={200}><img src="/img/profile_image.jpg" alt="" /></ScrollItem>
                    </div>
                </Scrollin>
            </section>

        </Layout>

        <Footer />
    
    </React.Fragment>
)

export default BooksPage
